import React from 'react';
import moment from 'moment';
import { Toolbar } from '../../components/toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography'
import { Table } from '../../components/table';
import { useDispatch, useSelector } from 'react-redux';
import { downloadBase64File } from '../../helpers/buttons'
import { getFiles, downloadFile } from '../../store/slim4/slim4.slice';
import { toast } from 'react-toastify';

const Slim4Files = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state.admin_user)
	const [selectedFile, setSelectedFile] = React.useState({
		checkedFiles : []
	});

	const columns = React.useMemo(() => {
		const handleCheck = (e) => {
			let name = e.target.name;
			let value = e.target.value;
			let checkedRows = selectedFile.checkedFiles;

			if (e.target.checked) {
				checkedRows.push({
					[name]: value
				});
			} else {
				checkedRows.splice(selectedFile.checkedFiles.indexOf(name), 1);
			}
			setSelectedFile({
				...selectedFile,
				[name]: e.target.checked,
				checkedFiles: checkedRows,
			});
		};

		return [
			{
				Header: ' ',
				width: 10,
				Cell: props => {
					return (<Checkbox
						onChange={handleCheck}
						inputProps={{ 'aria-label': 'controlled' }}
						checked={selectedFile[props?.row?.original?.filename]}
						value={props?.row?.original?.id}
						name={props?.row?.original?.filename}
					/>)
				}
			},
			{
				Header: 'Filename',
				accessor: 'filename',
				width: 280,
				Cell: props => {
					return (<Typography sx={{ color: '#CC6400' }} >{props.value}</Typography>)
				}
			},
			{
				Header: 'Source Directory',
				accessor: 'file_source_directory'
			},
			{
				Header: 'Target Directory',
				accessor: 'file_target_directory'
			},
			{
				Header: 'File Type',
				accessor: 'file_type',
				width: 50
			},
			{
				Header: 'File Size',
				accessor: 'file_size',
				width: 70,
				Cell: props => {
					return ''.concat((props.value/1024).toFixed(2), ' KB')
				}
			},
			{
				Header: 'File Created in Slim4',
				accessor: 'file_createdAt',
				width: 140,
				Cell: props => {
					return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
				}
			},
			{
				Header: 'File Fetched from Slim4',
				accessor: 'createdAt',
				width: 140,
				Cell: props => {
					return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		]
	}, [selectedFile])

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getFiles({
			route: '',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: filters
		}))
		.unwrap()
		.then(result => {
			callBack(result)
		})
		.catch(e => {
			toast.error(e)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleDownloadFile = ({
		file
	}) => {
		if(selectedFile.checkedFiles.length > 1){
			toast.error('Multiple files selected. Select 1 file only')
		}
		else if(selectedFile.checkedFiles.length === 0){
			toast.error('No file selected')
		}
		else {
			dispatch(downloadFile({
				route: 'download',
				data: {
					...selectedFile
				}
			}))
			.unwrap()
			.then(result => {
				if (result.status === 200) {
					const contentBase64 = result.data.contents;
					const filename = result.data.filename;
					let typeFormat = ''
					let fileExtension = filename.toUpperCase().split('.')[filename.split('.').length]
					if(fileExtension === 'XLSX') {
						typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					}
					else if(fileExtension === 'CSV') {
						typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					}
					// const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
				}
			})
			.catch(e => {
				toast.error(e)
			})
		}
	};

	return (
		<Grid container rowSpacing={1}>
			<Grid item md={12}>
				<Toolbar
					label='Slim4 Files'
					isDynamicButton={true}
					textDynamicButton={'Download'}
					colorDynamicButton={'spreadsheet'}
					onDynamicButton={handleDownloadFile}
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
			<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={() => console.log("state", selectedFile, selectedFile.checkedFiles.length)} >{'.'}</Typography>
		</Grid>
	)
}

export default Slim4Files;