import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { getASCIIuser, patchASCIIuser } from '../../store/ascii-user/asciiuser.thunk';
import { toast } from 'react-toastify';

const ASCIIuserDialog = ({
	isOpen,
	toggle
}) => {

	React.useEffect(() => {
		if (isOpen) {
			dispatch(getASCIIuser({
				route: 'user-management/'
			}))
				.unwrap()
				.then(result => {
					setState({
						...result.data,
					})
				})
				.catch(e => {
					toast.error(e)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		username: '',
		password: '',
		api_key: '',
		hasError: false
	})

	const handleUpdate = () => {
		let isError = false

		if (!state.username) {
			isError = true
		}
		if (!state.password) {
			isError = true
		}
		if (!state.api_key) {
			isError = true
		}

		setState({
			...state,
			hasError: isError
		})

		if (isError === false) {
			dispatch(patchASCIIuser({
				route: 'user-management/',
				data: {
					username: state.username,
					password: state.password,
					api_key: state.api_key
				}
			}))
			.catch(e => {
				toast.error(e)
			})

			setState({
				...state,
				username: '',
				password: '',
				api_key: '',
				hasError: false
			})
			toggle();
		}
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Change ASCII User Details</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='changePassword' />
					<Grid container spacing={2}>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='username'
								variant='outlined'
								label='Username'
								value={state.username}
								onChange={handleChange}
								error={state.hasError}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								type={'password'}
								name='password'
								variant='outlined'
								label='Password'
								value={state.password}
								onChange={handleChange}
								error={state.hasError}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								type={'password'}
								name='api_key'
								variant='outlined'
								label='API Key'
								value={state.api_key}
								onChange={handleChange}
								error={state.hasError}
							/>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ASCIIuserDialog;