/* eslint-disable no-useless-escape */
/* eslint-disable dot-location */
import React from 'react';
import Spinner from '../../components/spinner/spinner'
import { Toolbar } from '../../components/toolbar';
import { Table } from '../../components/table';
import {
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Input, DatePicker } from '../../components/inputs'
import { MasterSelect } from '../../components/select'
import {
	getLocMapping,
	getReportCodes,
	postUpload,
	downloadTemp
} from '../../store/wms_loc_mapping/wms_loc_mapping.slice';
import moment from 'moment'
import { downloadBase64File } from '../../helpers/buttons'
import { UpdateLocMappingDialog, CreateLocMappingDialog } from '../../components/dialogs';
import { toast } from 'react-toastify';

const LocMapping = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const { loading } = useSelector(state => state.fms_salem)
	const [updateDialog, setUpdateDialog] = React.useState(false);
	const [createDialog, setCreateDialog] = React.useState(false);
	const [trigger, setTrigger] = React.useState(false);
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		report: '',
		filters: {}
	})
	const [selectedMapping, setselectMapping] = React.useState({
		id:
		{
			whLocation: null,
			principal: null,
			dmg_loc: null
		}
	});
	const [handleSet, setHandleSet] = React.useState({
	})

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getLocMapping({
			route: 'locmapping/',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: {
				...filters,
				principal: state?.principal?.value,
				whLocation: state?.whLocation?.value
			}
		}))
			.unwrap()
			.then(result => {
				callBack(result)
			})
			.catch(e => {
				toast.error(e)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, createDialog, updateDialog])

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	const handleSelectChange = (e, name) => {
		setHandleSet({
			...handleSet,
			[name]: e
		})
		setState({
			...handleSet,
			...state,
			[name]: e
		})
	}


	const [controls, setControls] = React.useState({
		uploadDialog: false
	});

	/* upload start */
	const [uploadState, setuploadState] = React.useState({
		selectedFile: null,
		loaded: 0
	})

	const handleConfirm = () => {
		toggleUploadDialog();
		var file = uploadState.selectedFile;
		//const now = moment(new Date()).format('MM/DD/YYYY');
		if (file != null) {
			var reader = new FileReader();
			reader.onload = function () {
				const datafile = reader.result;
				dispatch(postUpload({
					route: 'locmapping/bulk/',
					data: {
						...state,
						fileName: file.name,
						datafile
					}
				}))
					.unwrap()
					.then(result => {
						if (result.status === 200) {
							//## type format of Base64
							let typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
							const contentBase64 = result.data.contents;
							const filename = result.data.filename;
							downloadBase64File({
								typeFormat: { typeFormat },
								contentBase64,
								filename
							})
							setControls({
								...controls,
								uploadDialog: !controls.uploadDialog
							})
						}
					})
			};
			reader.readAsDataURL(file);
		}
	}

	const [fetchDataState, setfetchDataState] = React.useState({})

	const handleUpload = (e) => {
		setuploadState({
			selectedFile: e.target.files[0],
			loaded: 0,
		})
		setfetchDataState({
			...fetchDataState,
			selectedFile: fetchDataState?.selectedFile || e.target.files[0].name
		})
	}

	const toggleUpdateDialog = React.useCallback(() => {
		setUpdateDialog(!updateDialog);
	
		if (updateDialog === true) {
		  setTrigger(!trigger);
		  setselectMapping({
			id: {
			  whLocation: null,
			  principal: null,
			  dmg_loc: null
			}
		  });
		}
	  }, [updateDialog, trigger]);

	const toggleCreateDialog = () => {
		setCreateDialog(!createDialog)
		if (createDialog === true) {
			setTrigger(!trigger)
		}
	}

	const toggleUploadDialog = () => {
		setControls({
			...controls,
			uploadDialog: !controls.uploadDialog
		})
	}

	const downloadTemplate = () => {
		dispatch(downloadTemp({
			route: 'converter/downloadUploadtemplate',
			data: {
				fileName: state?.source_code?.fileName
			}
		}))
			.unwrap()
			.then(result => {
				if (result.status === 200) {
					//## type format of Base64
					const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					const contentBase64 = result.data.contents;
					const filename = result.data.filename;
					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
				}
			})
			.catch(e => {
				toast.error(e)
			})
	};

	const columns = React.useMemo(() => [
		{
			Header: 'Edit',
			accessor: 'id',
			width: 100,
			Cell: props => {
				const onClick = () => {
					setselectMapping(prevSelectedMapping => ({
						...prevSelectedMapping,
						id: {
							whLocation: props.row.original.whLocation,
							principal: props.row.original.principal,
							dmg_loc: props.row.original.dmg_loc
						}
					}));
					toggleUpdateDialog();
				}
				return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{`Edit`}</Typography>)
			}
		},
		{
			Header: 'Warehouse Location',
			accessor: 'rc_whLocation.rc_code'
		},
		{
			Header: 'Principal (Customer)',
			accessor: 'rc_principal.rc_code'
		},
		{
			Header: 'Damaging Location',
			accessor: 'dmg_loc'
		},
		{
			Header: 'Route',
			accessor: 'route'
		},
		{
			Header: 'Status',
			accessor: 'mapping_status',
			width: 100,
			Cell: props => {
				return props.value ? 'Active' : 'Inactive'
			}
		},
		{
			Header: 'Created By',
			accessor: 'creator.user_email'
		},
		{
			Header: 'Created Date',
			accessor: 'createdAt',
			width: 140,
			Cell: props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header: 'Updated By',
			accessor: 'modifier.user_email'
		},
		{
			Header: 'Updated Date',
			accessor: 'updatedAt',
			width: 140,
			Cell: props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		}
	], [toggleUpdateDialog])

	/**Fetching the source code on the report*/
	React.useEffect(() => {
		dispatch(getReportCodes({
			route: 'locmapping/',
			report_id: 'LOC-MAPPING-A001'
		}))
			.unwrap()
			.then(result => {
				/** Get the source code of the report (na string in json format) */
				let report_source_code = result?.data?.data[0]?.report_source_code || 'pamparampampam';

				/** Check if source code (na string) is JSON.parsable */
				if (/^[\],:{}\s]*$/.test(report_source_code.replace(/\\["\\\/bfnrtu]/g, '@').
					replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
					replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
					setState({
						...state,
						source_code: JSON.parse(report_source_code)
					})
				}
				else {
					setState({
						...state,
						source_code: {}
					})
				}
			})
			.catch(e => {
				toast.error(e)
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, ['LOC-MAPPING-A001'])

	return (
		<Grid container rowSpacing={1}>
			<Spinner loading={loading} />
			<Grid item md={12}>
				<Toolbar
					label='Damage Location Mapping'
					onConfirm={handleConfirm}
					isOpen={controls.uploadDialog}
					toggleUploadDialog={toggleUploadDialog}
					isUpload={true}
					onUpload={handleUpload}
					isCreate
					onCreate={toggleCreateDialog}
					isDynamicButton={state?.source_code?.buttons?.isDynamicButton || false}
					textDynamicButton={state?.source_code?.text?.textDynamicButton || 'Download'}
					colorDynamicButton={'spreadsheet'}
					onDynamicButton={downloadTemplate}
				/>
			</Grid>

			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>

					{/**Dynamically Generate Select Components  */}
					{typeof state?.source_code?.dropdowns === 'object' ?
						state?.source_code?.dropdowns.map((foo, i) => (
							<Grid item md={12} key={i}>
								<MasterSelect
									size={12}
									key={i}
									paddingLeft={1}
									paddingRight={1}
									fullWidth
									label={foo.label}
									placeholder={foo.placeholder}
									name={foo.name}
									route={foo.route}
									type={foo.type}
									module_code='wms locmapping'
									value={state[foo.name]}
									handleChange={handleSelectChange}
								/>
							</Grid>
						)) : null
					}

					{/**Dynamically Generate Text Fields Components  */}
					{typeof state?.source_code?.textfields === 'object' ?
						state?.source_code?.textfields.map((foo, i) => (
							<Input
								key={i}
								fullWidth
								size={12}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								required={foo.isRequired}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

					{/**Dynamically Generate Dates Components  */}
					{typeof state?.source_code?.datefields === 'object' ?
						state?.source_code?.datefields.map((foo, i) => (
							<DatePicker
								key={i}
								size={6}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

				</Grid>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
			<CreateLocMappingDialog isOpen={createDialog} toggle={() => { toggleCreateDialog() }} />
			<UpdateLocMappingDialog isOpen={updateDialog} id={selectedMapping.id} toggle={() => { toggleUpdateDialog() }} />
		</Grid>
	)
}

export default LocMapping;