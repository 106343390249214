/* eslint-disable no-useless-escape */
/* eslint-disable dot-location */
import React from 'react';
import Spinner from '../../components/spinner/spinner'
import { Toolbar } from '../../components/toolbar';
import { Table } from '../../components/table';
import {
	Grid,
	Paper,
	Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getSOitemlist, getSOitemHeader, patchRevLeak, directPrinting } from '../../store/fms_salem/fms_salem.slice';
import { downloadBase64File, base64toBlob } from '../../helpers/buttons'
import { toast } from 'react-toastify';

const SalemSalesOrder = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const id_path = window.location.pathname;
	const id = id_path.replace('/slm/salem/', '')
	const dispatch = useDispatch();
	const [state, setState] = React.useState({ id })
	const { loading } = useSelector(state => state.fms_salem)
	const navigate = useNavigate();

	React.useEffect(() => {
		if (id !== null) {
			dispatch(getSOitemHeader({
				route: 'sales-invoice/items/header',
				filters: { so_conf_id: id }
			}))
				.unwrap()
				.then(result => {
					setState({
						...result.data,
					})
				})
				.catch(e => {
					toast.error(e)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.id]);

	const printDialog = () => {
		dispatch(directPrinting({
			route: 'sales-invoice/downloadUploadtemplate',
			data: {
				fk_so_id: state.id
			}
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				
				let typeFormat = 'application/pdf';
				const contentBase64 = result.data.contents;
				const filename = result.data.filename;
				if (filename.includes('.xlsx')) {
					typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
				}
				else {
					// file is a File object, this will also take a blob
					const dataUrl = window.URL.createObjectURL(base64toBlob(contentBase64, typeFormat))

					// Open the window
					const pdfWindow = window.open(dataUrl);

					// Call print on it
					pdfWindow.print();
				}
			}
		})
	}
	
	const printSIxlsx = () => {
		dispatch(directPrinting({
			route: 'sales-invoice/downloadSalesInvoiceFile',
			data: {
				fk_so_id: state.id
			}
		}))
			.unwrap()
			.then(result => {
				if (result.status === 200) {

					let typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					const contentBase64 = result.data.contents;
					const filename = result.data.filename;
					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
				}
			})
			.catch(e => {
				toast.error(e)
			})
	}

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getSOitemlist({
			route: 'sales-invoice/items/',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: { fk_so_id: state.id }
		}))
			.unwrap()
			.then(result => {
				callBack(result);
			})
			.catch(e => {
				toast.error(e)
			});
	}, [dispatch, state.id]);

	const navigateClick = () => {
		navigate(`/slm/salem/`);
	}
	const handleCheck = () => {
		dispatch(patchRevLeak({
			route: 'sales-invoice/items/revenueleak',
			data: {
				...state,
				fk_so_id: state.id
			}
		}))
			.unwrap()
			.then(result => {
				setState({
					...result.data,
				})
			})
			.catch(e => {
				toast.error(e)
			})
	}
	const columns = React.useMemo(() => [
		{
			Header: 'SKU Lines',
			accessor: 'so_itemRow'
		},
		{
			Header: 'Vendor SKU Code',
			accessor: 'so_SKU',
		},
		{
			Header: 'Customer SKU Code',
			accessor: 'so_customerSKUcode',
		},
		{
			Header: 'SKU Description',
			accessor: 'so_Desc'
		},
		{
			Header: 'Served Qty',
			accessor: 'so_shippedQty'
		},
		{
			Header: 'WMS UOM',
			accessor: 'so_WMSUOM'
		},
		{
			Header: 'Selling UOM',
			accessor: 'so_SellUOM'
		},
		{
			Header: 'Selling Price',
			accessor: 'so_price'
		},
		{
			Header: 'Unit Selling Price',
			Cell: props => {
				return (props.row.original.so_price / ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1)).toFixed(2)
			}
		},
		{
			Header: 'Discount',
			Cell: props => {
				return (props.row.original.so_price / ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1) * (props.row.original.so_discount / 100)).toFixed(2)
			}
		},
		{
			Header: 'Unit Net Selling Price',
			Cell: props => {
				return (props.row.original.so_price / ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1) * ((100 - props.row.original.so_discount) / 100)).toFixed(2)
			}
		},
		{
			Header: 'Total Amount',
			Cell: props => {
				return (props.row.original.so_shippedQty * (props.row.original.so_price / ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1))).toFixed(2)
			}
		},
		{
			Header: 'Vat',
			Cell: props => {
				return (props.row.original.so_shippedQty * (props.row.original.so_price / ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1)) * ((100 - props.row.original.so_discount) / 100) * (props.row.original.hh_slm_so_id.so_vatVal / 100)).toFixed(2)
			}
		},
		{
			Header: 'Transaction Type',
			Cell: props => {
				return (props.row.original.so_shippedQty * (props.row.original.so_price / ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1)) * ((100 - props.row.original.so_discount) / 100) * ((props.row.original.hh_slm_so_id.so_vatVal / 100) + 1)).toFixed(2)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
	], [])

	return (
		<Grid container rowSpacing={1}>
			<Spinner loading={loading} />
			<Grid item md={12}>
				<Toolbar
					label='&nbsp;Sales Order'
					navigateLabel='Sales Invoice Management&nbsp;\'
					onNavigate={navigateClick}
					isCheck={state.so_Status === 'Revenue Leak'}
					onCheck={handleCheck}
					isPrint={state.so_Status === 'Printed' || (state?.autoSALEMbool && (state.so_Status === 'Printed' || state.so_Status.includes('Success')))}
					onPrint={printDialog}
					textDownloadExcel='Download Sales Invoice'
					isDownloadExcel={state?.autoSALEMbool && (state.so_Status === 'Printed' || state.so_Status.includes('Successfully'))}
					onDownloadExcel={printSIxlsx}
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Grid item md={4}>
						<Typography >
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee Group Name</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_cg_name}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee Group Address</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_cg_address}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee Group Tin</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_cg_TIN}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Store Code</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_storeCode}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee ID</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_consignee}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee Name</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_consignee_name}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee Address</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_consignee_address}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Consignee Tin</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_consignee_TIN}</Typography>
						</Typography>
					</Grid>
					<Grid item md={4}>
						<Typography >
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Payment Method</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_paymentMethod}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Payment Term</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_paymentTerms}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Comercial in Charge</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_commercialinCharge}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>VAT</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_vatVal} %</Typography>
						</Typography>
					</Grid>
					<Grid item md={4}>
						<Typography >
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>SO Number</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_SONo}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>PO Reference</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_soRef}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Customer</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_customer}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>Status</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_Status}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>RDD</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.so_expectedShipmentTime1}</Typography>
							<Typography variant="button" display="block" sx={{ fontWeight: 'bold' }}>PO Date</Typography>
							<Typography variant='subtitle2' gutterBottom>{state.poDate}</Typography>
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SalemSalesOrder;