import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { postQuickCode } from '../../store/administration-quickcode/quickcode.slice';
import { toast } from 'react-toastify';

const CreateQuickCodeDialog = ({
	isOpen,
	toggle
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isQuickCodeError	:false,
		rc_type				:'',
		rc_code				:'',
		rc_desc				:'',
		rc_remarks1			:'',
		rc_remarks2			:'',
		rc_remarks3			:''
	})

	const handleCreate = () => {
		let hasQuickCode = false;
		if (state.rc_type.replace(/ /g, '') === '' || state.rc_code.replace(/ /g, '') === '' || state.rc_desc.replace(/ /g, '') === '') {
			hasQuickCode = true;
		}

		setState({
			...state,
			isQuickCodeError: hasQuickCode
		})

		if (!hasQuickCode) {
			dispatch(postQuickCode({
				route: '',
				data: {
					rc_type		:state.rc_type,
					rc_code		:state.rc_code,
					rc_desc		:state.rc_desc,
					rc_remarks1	:state.rc_remarks1,
					rc_remarks2	:state.rc_remarks2,
					rc_remarks3	:state.rc_remarks3
				}
			}))
			.catch(e => {
				toast.error(e)
			})

			setState({
				...state,
				isQuickCodeError	:false,
				rc_type				:'',
				rc_code				:'',
				rc_desc				:'',
				rc_remarks1			:'',
				rc_remarks2			:'',
				rc_remarks3			:''
			})
			toggle();
		}
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Create Quick Code</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='rc' />
					<Grid container spacing={2}>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='rc_type'
								error={state.isQuickCodeError}
								variant='outlined'
								label='Quick Code Mapping Type'
								value={state.rc_type}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name='rc_code'
								error={state.isQuickCodeError}
								variant='outlined'
								label='Quick Code Mapping ID'
								value={state.rc_code}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='rc_desc'
								error={state.isQuickCodeError}
								variant='outlined'
								label='Quick Code Mapping Description'
								value={state.rc_desc}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='rc_remarks1'
								variant='outlined'
								label='Quick Code Remarks1'
								value={state.rc_remarks1}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='rc_remarks2'
								variant='outlined'
								label='Quick Code Remarks2'
								value={state.rc_remarks2}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='rc_remarks3'
								variant='outlined'
								label='Quick Code Remarks3'
								value={state.rc_remarks3}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleCreate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CreateQuickCodeDialog;