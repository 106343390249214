//const img = require('../../src/assets/Img')
const modules = [
	{
		name: 'wms',
		label: 'Warehouse Management',
		route: '/wms',
		subModules: [
			{
				name: 'kairos report hub',
				route: '/wms/reporthub',
				label: 'Kairos Report Hub'
			},
			{
				name: 'kairos converter hub',
				route: '/wms/converter',
				label: 'Kairos Converter Hub'
			},
			{
				name: 'kairos dashboard',
				route: '/wms/dashboard',
				label: 'Kairos Dashboard'
			},
			{
				name: 'kairos interface',
				route: '/wms/interface',
				label: 'Kairos Interface'
			},
			{
				name: 'bin loc mapping',
				route: '/wms/locmapping',
				label: 'Bin Location Mapping'
			},
			{
				name: 'stc store mapping',
				route: '/wms/storemap',
				label: 'STC Store Mapping'
			},
		]
	},
	{
		name: 'tms',
		label: 'Transport Management',
		route: '/tms',
		subModules: [
			{
				name: 'nyke reporthub',
				route: '/tms/reporthub',
				label: 'Nyke Reporthub',
				imgsrc: 'https://cdn-icons-png.flaticon.com/512/819/819873.png'
			},
			// {
			// 	name: 'nyke converter',
			// 	route: '/tms/converter',
			// 	label: 'Nyke Converter'
			// },
			// {
			// 	name: 'nyke converter viewer',
			// 	route: '/tms/converterviewer',
			// 	label: 'Nyke Converter Viewer'
			// },
			{
				name: 'nyke data sync',
				route: '/tms/datasync',
				label: 'Nyke Data Sync'
			}
		]
	},
	{
		name: 'fms',
		label: 'Finance Management',
		route: '/fms',
		subModules: [
			{
				name: 'Plutus',
				route: '/fms/reporthub',
				label: 'Plutus Reporthub'
			},
		]
	},
	{
		name: 'slm',
		label: 'Sales Invoice Management',
		route: '/slm',
		subModules: [
			{
				name: 'Sales Invoice',
				route: '/slm/salem',
				label: 'Sales Invoice Management'
			},
			{
				name: 'SALEM Item Pricelist',
				route: '/slm/pricelist',
				label: 'SALEM Item Pricelist'
			},
			{
				name: 'Printed Sales Invoice',
				route: '/slm/printed',
				label: 'Printed Sales Invoice'
			},
			{
				name: 'Automated Sales Invoice Range',
				route: '/slm/auto',
				label: 'Automated Sales Invoice Range'
			},
			{
				name: 'Unsent Sales Invoice in ',
				route: '/slm/unsent',
				label: 'Unsent Sales Invoice in '
			},
		]
	},
	{
		name: 'slim4',
		label: 'Supply Chain Management',
		route: '/slim4',
		subModules: [
			{
				name: 'Slim4 Files',
				route: '/slim4/files',
				label: 'Slim4 Files'
			}
		]
	},
	{
		name: 'administration',
		label: 'Administration',
		route: '/administration',
		subModules: [
			{
				name: 'users',
				route: '/administration/user',
				label: 'Users'
			},
			{
				name: 'roles',
				route: '/administration/role',
				label: 'Roles'
			},
			{
				name: 'modules',
				route: '/administration/module',
				label: 'Modules'
			},
			{
				name: 'reports',
				route: '/administration/report',
				label: 'Reports'
			},
			{
				name: 'quickcodes',
				route: '/administration/quickcode',
				label: 'Quick Codes'
			}
		]
	}
]

export default modules;