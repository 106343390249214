import React from 'react';
import moment from 'moment';
import { Toolbar } from '../../components/toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import { Table } from '../../components/table';
import { useDispatch, useSelector } from 'react-redux';
import { getAutoSI  } from '../../store/fms_autosi/fms_autosi.slice';
import { CreateAutoSIDialog, UpdateAutoSIDialog } from '../../components/dialogs'; 
import { toast } from 'react-toastify';

const SalemAutoSI = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state.fms_autosi)
	const [updateTrigger, setUpdateTrigger] = React.useState(false);
	const [selectedAutoSI, setSelectAutoSI] = React.useState({ id: null });
	const [createDialog, setCreateDialog] = React.useState(false);
	const [updateDialog, setUpdateDialog] = React.useState(false);
	const [createTrigger, setCreateTrigger] = React.useState(false);

	const toggleCreateDialog = () => {
		setCreateDialog(!createDialog)

		if (createDialog === true) {
			setCreateTrigger(!createTrigger)
		}
	}

	const toggleUpdateDialog = () => {
		setUpdateDialog(!updateDialog)

		if (updateDialog === true) {
			setUpdateTrigger(!updateTrigger)
			setSelectAutoSI({ id:null })
		}
	}

	const columns = React.useMemo(() => [
		{
			Header: 'ID',
			accessor: 'id',
			width: 150,
			Cell: props => {
				const onClick = () => {
					setSelectAutoSI({
						...selectedAutoSI,
						id: props.value
					})
					toggleUpdateDialog()
				}
				return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{`Edit`}</Typography>)
			}
		},
		{
			Header:'HLS Warehouse Location',
			accessor:'slm_location'
		},
		{
			Header:'HLS Customer ID',
			accessor:'slm_customer'
		},
		{
			Header:'ASCII Location Code',
			accessor:'slm_ascii_loc'
		},
		{
			Header:'Sales Invoice No. From',
			accessor:'slm_si_from'
		},
		{
			Header:'Sales Invoice No. To',
			accessor:'slm_si_to',
			width:150
		},
		{
			Header:'BIR No.',
			width:150,
			accessor:'slm_bir_id'
		},
		{
			Header: 'Status',
			accessor: 'slm_status',
			width: 100,
			Cell: props => {
				return props.value ? 'Active' : 'Inactive'
			}
		},
		{
			Header: 'Created By',
			accessor: 'creator.user_email'
		},
		{
			Header: 'Created Date',
			accessor: 'createdAt',
			width: 140,
			Cell: props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	], [])

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getAutoSI({
			route: '',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: filters
		}))
		.unwrap()
		.then(result => {
			callBack(result)
		})
		.catch(e => {
			toast.error(e)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, false)
	return (
		<Grid container rowSpacing={1}>
			<Grid item md={12}>
				<Toolbar
					label='SALEM Auto Sales Invoice Maintenance'
					onCreate={toggleCreateDialog}
					isCreate={true}
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
			<CreateAutoSIDialog isOpen={createDialog} toggle={() => { toggleCreateDialog() }} />
			<UpdateAutoSIDialog isOpen={updateDialog} id={selectedAutoSI.id} toggle={() => { toggleUpdateDialog() }} />
		</Grid>
	)
}

export default SalemAutoSI;