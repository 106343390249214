import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { postPricelist } from '../../store/fms_salem/fms_salem.thunk';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { toast } from 'react-toastify';

const CreatePricelistDialog = ({
	isOpen,
	toggle
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isPricelistError: false,
		itm_skuCode: '',
		itm_consigneeSKUcode: '',
		itm_skuDescription: '',
		itm_consignee: '',
		itm_supplier: '',
		itm_wmsuom: '',
		itm_price: '',
		itm_discount: '',
		itm_priceValidityFrom: '',
		itm_priceValidityTo: ''
	})

	const handleCreate = () => {
		let hasPricelist = false;
		if (state.itm_skuCode.replace(/ /g, '') === '' ||
			state.itm_skuDescription.replace(/ /g, '') === '' ||
			state.itm_consignee.replace(/ /g, '') === '' ||
			state.itm_supplier.replace(/ /g, '') === '' ||
			state.itm_wmsuom.replace(/ /g, '') === '' ||
			state.itm_price.replace(/ /g, '') === '' ||
			state.itm_priceValidityFrom === null||
			state.itm_priceValidityTo === null) {
			hasPricelist = true;
		}

		setState({
			...state,
			isPricelistError: hasPricelist
		})
		if (!hasPricelist) {
			dispatch(postPricelist({
				route: 'data-management/',
				data: {
					itm_skuCode: state.itm_skuCode,
					itm_consigneeSKUcode: state.itm_consigneeSKUcode,
					itm_skuDescription: state.itm_skuDescription,
					itm_consignee: state.itm_consignee,
					itm_supplier: state.itm_supplier,
					itm_wmsuom: state.itm_wmsuom,
					itm_price: state.itm_price,
					itm_discount: state.itm_discount,
					itm_priceValidityFrom: state.itm_priceValidityFrom,
					itm_priceValidityTo: state.itm_priceValidityTo
				}
			}))
			.catch(e => {
				toast.error(e)
			})

			setState({
				...state,
				isPricelistError: false,
				itm_skuCode: '',
				itm_consigneeSKUcode: '',
				itm_skuDescription: '',
				itm_consignee: '',
				itm_supplier: '',
				itm_wmsuom: '',
				itm_price: '',
				itm_discount: '',
				itm_priceValidityFrom: '',
				itm_priceValidityTo: ''
			})
			toggle();
		}
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Create Price List</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='price' />
					<Grid container spacing={2}>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='itm_skuCode'
								error={state.isPricelistError}
								variant='outlined'
								label='SKU Code'
								value={state.itm_skuCode}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='itm_consigneeSKUcode'
								//error={state.isPricelistError}
								variant='outlined'
								label='Consignee SKU Code'
								value={state.itm_consigneeSKUcode}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name='itm_skuDescription'
								error={state.isPricelistError}
								variant='outlined'
								label='SKU Description'
								value={state.itm_skuDescription}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='itm_consignee'
								error={state.isPricelistError}
								variant='outlined'
								label='Consignee'
								value={state.itm_consignee}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='itm_supplier'
								error={state.isPricelistError}
								variant='outlined'
								label='Principal'
								value={state.itm_supplier}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								name='itm_wmsuom'
								error={state.isPricelistError}
								variant='outlined'
								label='UOM'
								value={state.itm_wmsuom}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								required
								fullWidth
								type="number"
								name='itm_price'
								error={state.isPricelistError}
								variant='outlined'
								label='Price'
								value={state.itm_price}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='itm_discount'
								variant='outlined'
								label='Discount (%)'
								type="number"
								value={state.itm_discount}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item container>
							<LocalizationProvider dateAdapter={DateAdapter} >

								<DatePicker
									clearable
									required
									label="Price Expiry From"
									value={state.itm_priceValidityFrom}
									name='itm_priceValidityFrom'
									onChange={(newValue) => {
										setState({
											...state,
											itm_priceValidityFrom: newValue
										});
									}}
									renderInput={(params) => <TextField 
										fullWidth {...params} helperText={null} />}
								/>
							</LocalizationProvider>
						</Grid><Grid item container>
							<LocalizationProvider dateAdapter={DateAdapter} >

								<DatePicker
									clearable
									required
									label="Price Expiry From"
									value={state.itm_priceValidityTo}
									name='itm_priceValidityTo'
									onChange={(newValue) => {
										setState({
											...state,
											itm_priceValidityTo: newValue
										});
									}}
									renderInput={(params) => <TextField 
										fullWidth {...params} helperText={null} />}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleCreate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CreatePricelistDialog;