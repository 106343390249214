import React from 'react';
import moment from 'moment';
import { Toolbar } from '../../components/toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import { Table } from '../../components/table';
import { useDispatch, useSelector } from 'react-redux';
import { CreateQuickCodeDialog, UpdateQuickCodeDialog } from '../../components/dialogs';
import { getQuickCode } from '../../store/administration-quickcode/quickcode.slice';
import { toast } from 'react-toastify';

const QuickCode = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const dispatch = useDispatch();
	const [createTrigger, setCreateTrigger] = React.useState(false);
	const [updateTrigger, setUpdateTrigger] = React.useState(false);
	const { loading } = useSelector(state => state.admin_quickcode)
	const [createDialog, setCreateDialog] = React.useState(false);
	const [updateDialog, setUpdateDialog] = React.useState(false);
	const [selectedQuickCode, setSelectedQuickCode] = React.useState({ reasoncode_email: null });

	const columns = React.useMemo(() => [
		{
			Header: 'Action',
			accessor: 'rc_id',
			width: 80,
			Cell: props => {
				const onClick = () => {
					setSelectedQuickCode({
						...selectedQuickCode,
						rc_id: props.value
					})
					toggleUpdateDialog()
				}
				return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{`Edit`}</Typography>)
			}
		},
		{
			Header: 'Quick Code Mapping Type',
			accessor: 'rc_type',
			width: 150
		},
		{
			Header: 'Quick Code Mapping ID',
			accessor: 'rc_code'
		},
		{
			Header: 'Quick Code Mapping Description',
			width: 200,
			accessor: 'rc_desc'
		},
		{
			Header: 'Status',
			accessor: 'rc_status',
			width: 100,
			Cell: props => {
				return props.value ? 'Active' : 'Inactive'
			}
		},
		{
			Header: 'Remarks1',
			accessor: 'rc_remarks1'
		},
		{
			Header: 'Remarks2',
			accessor: 'rc_remarks2'
		},
		{
			Header: 'Remarks3',
			accessor: 'rc_remarks3'
		},
		{
			Header: 'Created Date',
			accessor: 'createdAt',
			width: 140,
			Cell: props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header: 'Created By',
			accessor: 'creator.user_email'
		},
		{
			Header: 'Updated Date',
			accessor: 'updatedAt',
			width: 140,
			Cell: props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header: 'Updated By',
			accessor: 'modifier.user_email'
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	], [])

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getQuickCode({
			route: '',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: filters
		}))
		.unwrap()
		.then(result => {
			callBack(result)
		})
		.catch(e => {
			toast.error(e)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createTrigger, updateTrigger])

	const toggleCreateDialog = () => {
		setCreateDialog(!createDialog)

		if (createDialog === true) {
			setCreateTrigger(!createTrigger)
		}
	}

	const toggleUpdateDialog = () => {
		setUpdateDialog(!updateDialog)

		if (updateDialog === true) {
			setUpdateTrigger(!updateTrigger)
			setSelectedQuickCode({ rc_type: null, rc_code: null })
		}
	}

	return (
		<Grid container rowSpacing={1}>
			<Grid item md={12}>
				<Toolbar
					label='Quick Codes'
					isCreate
					onCreate={toggleCreateDialog}
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
			<CreateQuickCodeDialog isOpen={createDialog} toggle={() => { toggleCreateDialog() }} />
			<UpdateQuickCodeDialog isOpen={updateDialog} rc_id={selectedQuickCode.rc_id} toggle={() => { toggleUpdateDialog() }} />
		</Grid>
	)
}

export default QuickCode;