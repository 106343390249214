/* eslint-disable no-unused-vars */
import React from 'react'
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

const DatePicker = ({
	label,
	name,
	size,
	handleChange,
	value,
	isLabelVisible,
	isDisabled
}) => {
	const [focus, setFocus] = React.useState(false)
	const [labelVisible, setLabelVisible] = React.useState(isLabelVisible)
	const theme = useTheme()

	const onChange = (value) => {
		const e = {
			target: {
				name: name,
				value: value ? dayjs(value).add(8, 'hour') : null
			}
		}
		handleChange(e)
	}
	return (
		<Grid item xs={12} md={size} component='div' style={{
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1)
		}}>
			<Typography variant='subtitle2' sx={{
					display: labelVisible ? 'visible' : 'none',
					color: focus ? 'primary.main' : 'text.primary',
				}}>{label}
			</Typography>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<MUIDatePicker
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
					clearable
					disabled={isDisabled}
					label={labelVisible ? '' : label}
					value={value}
					onChange={onChange}
					slotProps={{ textField: { size: 'small' }}}
				/>
			</LocalizationProvider>
		</Grid>
	)
}

DatePicker.defaultProps = {
	isLabelVisible: false,
	isDisabled: false,
	label: '',
	placeholder: '',
	size: 6,
	value: null,
	handleChange: () => { }
}

export default DatePicker