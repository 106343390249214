import { createSlice } from '@reduxjs/toolkit';
import { getFiles, downloadFile } from './slim4.thunk';

const initialState = {
	loading: false
}

const slice = createSlice({
	name: 'slim4Files',
	initialState,
	reducers: {
		resetAction: () => initialState
	},
	extraReducers: {
		[getFiles.pending]: (state, action) => {
			state.loading = true
		},
		[getFiles.fulfilled]: (state, action) => {
			state.loading = false
		},
		[getFiles.rejected]: (state, action) => {
			state.loading = false
		},
		[downloadFile.pending]: (state, action) => {
			state.loading = true
		},
		[downloadFile.fulfilled]: (state, action) => {
			state.loading = false
		},
		[downloadFile.rejected]: (state, action) => {
			state.loading = false
		},
	}
})

export {
	getFiles,
	downloadFile
}

export default slice.reducer