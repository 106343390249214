import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { Switch } from '../inputs';
import { getLocMappingDetails, patchLocMapping } from '../../store/wms_loc_mapping/wms_loc_mapping.slice';
import { MasterSelect } from '../select';
import { toast } from 'react-toastify';

const UpdateLocMapping = ({
	isOpen,
	toggle,
	id
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isMappingError: false,
		id: {
			whLocation: '',
			principal: '',
			dmg_loc: '',
			route: '',
			mapping_status: ''
		}
	});

	const handleUpdate = () => {
		dispatch(patchLocMapping({
			route: 'LocMapping',
			data: {
				whLocation: state.id.whLocation.value,
				principal: state.id.principal.value,
				dmg_loc: state.id.dmg_loc,
				route: state.id.route,
				mapping_status: state.id.mapping_status
			}
		}))
		.catch(e => {
			toast.error(e)
		})

		setState({
			...state,
			isMappingError: false,
			id: {
				whLocation: '',
				principal: '',
				dmg_loc: '',
				route: '',
				mapping_status: ''
			}
		})
		toggle();
	}

	const handleChange = (e) => {
		setState({
			...state,
			id:
			{
				...state.id,
				[e.target.name]: e.target.value
			}
		})
	}

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			id:
			{
				...state.id,
				[name]: e
			}
		})
	}

	React.useEffect(() => {
		if (id !== null && id !== 'Unknown ID') {
			dispatch(getLocMappingDetails({
				route: 'LocMapping/details',
				filters: { ...id }
			}))
				.unwrap()
				.then(result => {
					setState({
						...state,
						id: {
							...result.data,
							principal: {
								value: result.data?.rc_principal?.rc_id,
								label: result.data?.rc_principal?.rc_desc
							},
							whLocation: {
								value: result.data?.rc_whLocation?.rc_id,
								label: result.data?.rc_whLocation?.rc_desc
							}
						},
					})
				})
				.catch(e => {
					toast.error(e)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Update Location Mapping</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<MasterSelect
								isDisabled
								fullWidth
								placeholder='Warehouse Location'
								label='Warehouse Location'
								name='whLocation'
								route='quickcode'
								type='HLS Warehouse Location'
								value={state.id.whLocation || ''}
								handleChange={handleSelectChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<MasterSelect
								isDisabled
								fullWidth
								placeholder='Principal (Customer)'
								label='Principal (Customer)'
								name='principal'
								route='quickcode'
								type='HLS Principal'
								value={state.id.principal || ''}
								handleChange={handleSelectChange}
							/>
						</Grid>

						<Grid item container>
							<TextField
								required
								disabled
								fullWidth
								name='dmg_loc'
								variant='outlined'
								label='Damage Location'
								value={state.id.dmg_loc}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								fullWidth
								name='route'
								variant='outlined'
								label='Route'
								value={state.id.route}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Switch isLabelVisible
								label='Status'
								name='mapping_status'
								checked={state.id.mapping_status}
								handleChange={(e) => setState({ ...state, id: { ...state.id, mapping_status: e.target.checked } })} />
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

UpdateLocMapping.defaultProps = {
	isOpen: false,
	toggle: false,
	id: 'Unknown ID'
}

export default UpdateLocMapping;