import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Grid,
	DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Spinner } from '..';
import { Switch } from '../inputs';
import { getPricelistDetails, patchPricelist } from '../../store/fms_salem/fms_salem.thunk';
import { toast } from 'react-toastify';

const UpdateQuickCodeDialog = ({
	isOpen,
	toggle,
	id
}) => {

	const dispatch = useDispatch();

	const [state, setState] = React.useState({
		isPricelistError: false,
		id: '',
		itm_skuCode: '',
		itm_consigneeSKUcode: '',
		itm_statusUpdate: false,
		itm_skuDescription: '',
		itm_consignee: '',
		itm_supplier: '',
		itm_wmsuom: '',
		itm_price: '',
		itm_discount: '',
		itm_priceValidityFrom: '',
		itm_priceValidityTo: '',
		itm_status: false,
	});

	const handleUpdate = () => {
		dispatch(patchPricelist({
			route: 'data-management',
			data: {
				id: state.id,
				isPricelistError: state.isPricelistError,
				itm_statusUpdate: state.itm_statusUpdate,
				itm_skuCode: state.itm_skuCode,
				itm_consigneeSKUcode: state.itm_consigneeSKUcode,
				itm_skuDescription: state.itm_skuDescription,
				itm_consignee: state.itm_consignee,
				itm_supplier: state.itm_supplier,
				itm_wmsuom: state.itm_wmsuom,
				itm_price: state.itm_price,
				itm_discount: state.itm_discount,
				itm_priceValidityFrom: state.itm_priceValidityFrom,
				itm_priceValidityTo: state.itm_priceValidityTo,
				itm_status: state.itm_status,
			}
		}))
		.catch(e => {
			toast.error(e)
		})

		setState({
			...state,
			isPricelistError: false,
			id: '',
			itm_skuCode: '',
			itm_consigneeSKUcode: '',
			itm_statusUpdate: false,
			itm_skuDescription: '',
			itm_consignee: '',
			itm_supplier: '',
			itm_wmsuom: '',
			itm_price: '',
			itm_discount: '',
			itm_priceValidityFrom: '',
			itm_priceValidityTo: '',
			itm_status: false,
		})
		toggle();
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	React.useEffect(() => {
		if (id !== null && id !== 'Unknown SKU Pricelist ID') {
			dispatch(getPricelistDetails({
				route: 'data-management/details',
				filters: { id }
			}))
				.unwrap()
				.then(result => {
					setState({
						...state,
						...result.data[0],
						itm_statusUpdate: result.data[0].itm_status,
					})
				})
				.catch(e => {
					toast.error(e)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<Dialog open={isOpen}>
			<DialogTitle>Update Pricelist</DialogTitle>
			<DialogContent dividers>
				<div>
					<Spinner reducer='itm' />
					<Grid container spacing={2}>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='itm_skuCode'
								error={state.isPricelistError}
								variant='outlined'
								label='SKU Code'
								value={state.itm_skuCode}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item container>
							<TextField
								disabled
								fullWidth
								name='itm_consigneeSKUcode'
								variant='outlined'
								label='Consignee SKU Code'
								value={state.itm_consigneeSKUcode}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								disabled
								required
								fullWidth
								name='itm_skuDescription'
								error={state.isPricelistError}
								variant='outlined'
								label='SKU Description'
								value={state.itm_skuDescription}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='itm_consignee'
								error={state.isPricelistError}
								variant='outlined'
								label='STC\Consignee'
								value={state.itm_consignee}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='itm_supplier'
								error={state.isPricelistError}
								variant='outlined'
								label='Principal'
								value={state.itm_supplier}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='itm_wmsuom'
								error={state.isPricelistError}
								variant='outlined'
								label='WMS UOM'
								value={state.itm_wmsuom}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								name='itm_selluom'
								error={state.isPricelistError}
								variant='outlined'
								label='Sell UOM'
								value={state.itm_selluom}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								required
								fullWidth
								type="number"
								name='itm_price'
								error={state.isPricelistError}
								variant='outlined'
								label='Price'
								value={state.itm_price}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								fullWidth
								name='itm_discount'
								variant='outlined'
								label='Discount (%)'
								type="number"
								value={state.itm_discount}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								fullWidth
								name='itm_priceValidityFrom'
								variant='outlined'
								label='Price Expiry'
								value={state.itm_priceValidityFrom}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item container>
							<TextField
								disabled
								fullWidth
								name='itm_priceValidityTo'
								variant='outlined'
								label='Price Expiry'
								value={state.itm_priceValidityTo}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item md={2} xs={12}>
							<Switch isLabelVisible
								label='Status'
								name='itm_status'
								checked={state.itm_statusUpdate}
								handleChange={(e) => setState({ ...state, itm_statusUpdate: e.target.checked })} />
						</Grid>
					</Grid>
				</div>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={toggle} color='secondary'>Cancel</Button>
				<Button variant='contained' onClick={handleUpdate}>Save</Button>
			</DialogActions>
		</Dialog>
	);
}

UpdateQuickCodeDialog.defaultProps = {
	isOpen: false,
	toggle: false,
	id: 'Unknown SKU Pricelist ID'
}

export default UpdateQuickCodeDialog;