import React from 'react';
import moment from 'moment';
import { Toolbar } from '../../components/toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import { Table } from '../../components/table';
import { useDispatch, useSelector } from 'react-redux';
import { getPricelist, postUpload, downloadTemp } from '../../store/fms_salem/fms_salem.slice';
import { downloadBase64File } from '../../helpers/buttons'
import { CreatePricelistDialog, UpdatePricelistDialog } from '../../components/dialogs';
import { toast } from 'react-toastify';

const SalemItemPrice = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state.fms_salem)
	const [updateTrigger, setUpdateTrigger] = React.useState(false);
	const [updateDialog, setUpdateDialog] = React.useState(false);
	const [selectedPricelist, setSelectPricelist] = React.useState({ pricelist_email: null });
	const [createDialog, setCreateDialog] = React.useState(false);
	const [createTrigger, setCreateTrigger] = React.useState(false);

	const toggleUploadDialog = () => {
		setControls({
			...controls,
			uploadDialog: !controls.uploadDialog
		})
	}

	const toggleCreateDialog = () => {
		setCreateDialog(!createDialog)

		if (createDialog === true) {
			setCreateTrigger(!createTrigger)
		}
	}

	const toggleUpdateDialog = () => {
		setUpdateDialog(!updateDialog)

		if (updateDialog === true) {
			setUpdateTrigger(!updateTrigger)
			setSelectPricelist({itm_supplier:null, itm_skuCode:null})
		}
	}

	const handleConfirm = () => {
		var file = uploadState.selectedFile;
		if (file != null) {
			var reader = new FileReader();
			reader.onload = function () {
				const datafile = reader.result;
				//console.log(data);
				dispatch(postUpload({
					route: 'data-management/bulkupload',
					data: {
						fileName: file.name,
						datafile
					}
				}))
				.unwrap()
				.then(result => {
					if (result.status === 200) {
						//## type format of Base64
						const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
						const contentBase64 = result.data.contents;
						const filename = result.data.filename;
						downloadBase64File({
							typeFormat: { typeFormat },
							contentBase64,
							filename
						})

						setControls({
							...controls,
							uploadDialog: !controls.uploadDialog
						})
					}
				})
				.catch(e => {
					toast.error(e)
				})
			};
			reader.readAsDataURL(file);
		}
	}

	const downloadTemplate = () => {
				//console.log(data);
				dispatch(downloadTemp({
					route: 'data-management/downloadUploadtemplate',
					data: {
						fileName: 'SALEM Item Pricelist Bulk Upload File'
					}
				}))
				.unwrap()
				.then(result => {
					if (result.status === 200) {
						//## type format of Base64
						const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
						const contentBase64 = result.data.contents;
						const filename = result.data.filename;
						downloadBase64File({
							typeFormat: { typeFormat },
							contentBase64,
							filename
						})
					}
				})
				.catch(e => {
					toast.error(e)
				})
			};

	const [fetchDataState, setfetchDataState] = React.useState({})

	const [controls, setControls] = React.useState({
		uploadDialog: false
	});

	/* upload start */
	const [uploadState, setuploadState] = React.useState({
		selectedFile: null,
		loaded: 0
	})

	const handleUpload = (e) => {
		setuploadState({
			selectedFile: e.target.files[0],
			loaded: 0,
		})
		setfetchDataState({
			...fetchDataState,
			selectedFile: fetchDataState?.selectedFile || e.target.files[0].name
		})
	}

	const columns = React.useMemo(() => [
		{
			Header: 'ID',
			accessor: 'id',
			width: 150,
			Cell: props => {
				const onClick = () => {
					setSelectPricelist({
						...selectedPricelist,
						id: props.value
					})
					toggleUpdateDialog()
				}
				return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{`Edit`}</Typography>)
			}
		},
		{
			Header:'Vendor SKU Code',
			accessor:'itm_skuCode'
		},
		{
			Header:'Customer SKU Code',
			accessor:'itm_customerSKUcode'
		},
		{
			Header:'SKU Description',
			accessor:'itm_skuDescription',
			width:150
		},
		{
			Header:'Consignee/Customer',
			width:150,
			accessor:'itm_customer'
		},
		{
			Header:'Supplier ID',
			accessor:'itm_supplier'
		},
		{
			Header:'WMS UOM',
			accessor:'itm_wmsuom'
		},
		{
			Header:'Selling UOM',
			accessor:'itm_selluom'
		},
		{
			Header: 'Selling Price',
			accessor: 'itm_price'
		},
		{
			Header: 'Discount',
			accessor: 'itm_discount'
		},
		{
			Header:'Price Availability From',
			accessor:'itm_priceValidityFrom',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD') : props.value
			}
		},
		{
			Header:'Price Availability To',
			accessor:'itm_priceValidityTo',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD') : props.value
			}
		},
		{
			Header: 'Status',
			accessor: 'itm_status',
			width: 100,
			Cell: props => {
				return props.value ? 'Active' : 'Inactive'
			}
		},
		{
			Header: 'Created By',
			accessor: 'creator.user_email'
		},
		{
			Header: 'Created Date',
			accessor: 'createdAt',
			width: 140,
			Cell: props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	], [])

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getPricelist({
			route: 'data-management/',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: filters
		}))
		.unwrap()
		.then(result => {
			callBack(result)
		})
		.catch(e => {
			toast.error(e)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, false)
	return (
		<Grid container rowSpacing={1}>
			<Grid item md={12}>
				<Toolbar
					label='SALEM Item Pricelist'
					isUpload={true}
					onUpload={handleUpload}
					onConfirm={handleConfirm}
					toggleUploadDialog={toggleUploadDialog}
					isOpen={controls.uploadDialog}
					onCreate={toggleCreateDialog}
					isDynamicButton={true}
					onDynamicButton={downloadTemplate}
					textDynamicButton='Download Pricelist Template'
					//## will fix this after
					//isCreate
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
			<CreatePricelistDialog isOpen={createDialog} toggle={() => { toggleCreateDialog() }} />
			<UpdatePricelistDialog isOpen={updateDialog} id={selectedPricelist.id} toggle={() => { toggleUpdateDialog() }} />
		</Grid>
	)
}

export default SalemItemPrice;