import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../helpers/api';
import { toast } from 'react-toastify';

const baseURL = '/slim4/files';
const headers = {
	'Content-Type': 'application/json',
}

const getFiles = createAsyncThunk('files/get',
	async ({ route, page, totalPage, orderBy, filters }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).get(`${baseURL}/${route}`, {
				params: {
					page,
					totalPage,
					orderBy,
					...filters
				}
			})

			return res.data
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

const downloadFile = createAsyncThunk('files/download',
	async ({ route, data }, { rejectWithValue }) => {
		try {
			const res = await API({
				requestHeaders: {
					...headers
				}
			}).post(`${baseURL}/${route}`, {
				data
			})
				.then(result => {
					if (result.status === 200) {
						toast.success(`Download successfully!`)
					}
					return result
				})

			return res
		}
		catch (e) {
			if (e.response && e.response.data) {
				toast.error(`${e.response.data.message}`)
			}
			return rejectWithValue(e)
		}
	}
)

export {
	getFiles,
	downloadFile
}