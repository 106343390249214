/* eslint-disable no-useless-escape */
/* eslint-disable dot-location */
import React from 'react';
import Spinner from '../../components/spinner/spinner'
import { Toolbar } from '../../components/toolbar';
import { Table } from '../../components/table';
import {
	Grid,
	Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Input, DatePicker } from '../../components/inputs'
import { MasterSelect } from '../../components/select'
import {
	getSIlist,
	getReportCodes,
	getReport
} from '../../store/fms_salem/fms_salem.slice';
import moment from 'moment'
import { downloadBase64File } from '../../helpers/buttons'
import { toast } from 'react-toastify';

const SalemPrintedInvoice = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {

	const { loading } = useSelector(state => state.fms_salem)
	const dispatch = useDispatch();
	const [state, setState] = React.useState({
		report: ''
	})

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getSIlist({
			route: 'sales-invoice/printedInvoices',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: {
				...filters,
				si_warehouseId: state?.si_warehouseId?.label,
				si_customerId: state?.si_customerId?.label,
				dateFrom: state?.dateFrom,
				dateTo: state?.dateTo
			}
		}))
		.unwrap()
		.then(result => {
			callBack(result)
		})
		.catch(e => {
			toast.error(e)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state])

	const handleSelectChange = (e, name) => {
		setState({
			...state,
			[name]: e
		})
	}

	const handleDownloadExcel = () => {
		dispatch(getReport({
			route: 'sales-invoice/',
			downloadType: 'xlsx',
			data: {
				...state,
				report: { value: 'SLM-INV-MNGT-02' }
			}
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				//## type format of Base64
				const typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				const contentBase64 = result.data.contents;
				const filename = result.data.filename;
				downloadBase64File({
					typeFormat: { typeFormat },
					contentBase64,
					filename
				})
			}
		})
		.catch(e => {
			toast.error(e)
		})
	}

	const handleChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}

	const columns = React.useMemo(() => [
		{
			Header: 'Warehouse Location',
			accessor: 'si_warehouseId'
		},
		{
			Header: 'Principal (Customer)',
			accessor: 'si_customerId'
		},
		{
			Header:'Sales Invoice Date',
			accessor:'createdAt',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header:'RDD',
			accessor:'si_RDD',
			width:140,
			Cell:props => {
				return props.value ? moment(props.value).format('YYYY-MM-DD HH:mm:ss') : props.value
			}
		},
		{
			Header: 'SO No',
			accessor: 'si_SONo'
		},
		{
			Header: 'Sales Invoice No',
			accessor: 'manual_si.grn_manual_sales_invoice'
		},
		{
			Header: 'PO Reference',
			accessor: 'si_refNo'
		},
		{
			Header: 'Customer',
			Cell: props => {
				return (props.row.original.si_storeCode+' - '+props.row.original.si_consignee_name)
			}
		},
		{
			Header: 'VATable Sales Gross of Discount',
			accessor: 'si_vat_gross_dc'
		},
		{
			Header: 'Less: Total Discounts & Others',
			accessor: 'si_total_discount'
		},
		{
			Header: 'Total Sales',
			accessor: 'si_total_sales'
		},
		{
			Header: 'VAT',
			accessor: 'si_vat'
		},
		{
			Header: 'Total Amount Due',
			accessor: 'si_total_amount_due'
		},
	], [])

	/**Fetching the source code on the report*/
	React.useEffect(() => {
		dispatch(getReportCodes({
			route: 'sales-invoice/',
			report_id: 'SLM-INV-MNGT-02'
		}))
		.unwrap()
		.then(result => {
			//console.log("result",result.data.data[0].report_id)
			/** Get the source code of the report (na string in json format) */
			let report_source_code = result?.data?.data[0]?.report_source_code || 'pamparampampam';

			/** Check if source code (na string) is JSON.parsable */
			if (/^[\],:{}\s]*$/.test(report_source_code.replace(/\\["\\\/bfnrtu]/g, '@').
				replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
				replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
				setState({
					...state,
					source_code: JSON.parse(report_source_code)
				})
			}
			else {
				setState({
					...state,
					source_code: {}
				})
			}
		})
		.catch(e => {
			toast.error(e)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [''])

	return (
		<Grid container rowSpacing={1}>
			<Spinner loading={loading} />
			<Grid item md={12}>
				<Toolbar
					label='Printed Sales Invoice'
					isDownloadExcel={state?.source_code?.buttons?.isDownloadExcel || false}
					onDownloadExcel={handleDownloadExcel}
				/>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>

					{/**Dynamically Generate Select Components  */}
					{typeof state?.source_code?.dropdowns === 'object' ?
						state?.source_code?.dropdowns.map((foo, i) => (
							<Grid item md={12} key={i}>
								<MasterSelect
									size={12}
									key={i}
									paddingLeft={1}
									paddingRight={1}
									fullWidth
									label={foo.label}
									placeholder={foo.placeholder}
									name={foo.name}
									route={foo.route}
									type={foo.type}
									module_code='salem reporthub'
									value={state[foo.name]}
									handleChange={handleSelectChange}
								/>
							</Grid>
						)) : null
					}

					{/**Dynamically Generate Text Fields Components  */}
					{typeof state?.source_code?.textfields === 'object' ?
						state?.source_code?.textfields.map((foo, i) => (
							<Input
								key={i}
								fullWidth
								size={12}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								required={foo.isRequired}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

					{/**Dynamically Generate Dates Components  */}
					{typeof state?.source_code?.datefields === 'object' ?
						state?.source_code?.datefields.map((foo, i) => (
							<DatePicker
								key={i}
								size={6}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								name={foo.name}
								value={state[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

				</Grid>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SalemPrintedInvoice;