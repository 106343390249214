import React from 'react';
import { Toolbar as MUIToolbar, Button, Paper, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { UploadDialog } from '../dialogs';

function Toolbar({
	isCancel,
	onCancel,
	isCreate,
	onCreate,
	isConfirm,
	onConfirm,
	textConfirm,
	isDownloadExcel,
	onDownloadExcel,
	isDownloadPdf,
	onDownloadPdf,
	textDownloadPdf,
	label,
	isSynchronize,
	onSynchronize,
	uploadType,
	isUpload,
	onUpload,
	toggleUploadDialog,
	isOpen,
	isGenerate,
	onGenerate,
	isCheck,
	onCheck,
	navigateLabel,
	onNavigate,
	isPrint,
	onPrint,
	isDynamicButton,
	onDynamicButton,
	colorDynamicButton,
	textDynamicButton,
	textDownloadExcel,
	onChecked,
	textChecked,
	isChecked,
}) {

	// const [controls,setControls] = React.useState({
	// 	uploadDialog
	// });

	return (
		<Paper elevation={0} variant='outlined' sx={{
			paddingLeft: 1,
			paddingRight: 1
		}}>
			<MUIToolbar>
				<Typography variant='h6' style={{ cursor: "pointer" }} onClick={onNavigate} >{navigateLabel}</Typography>
				<Typography variant='h6'>{label}</Typography>
				<div style={{ flexGrow: 1 }} />
				<div >
					<FormControlLabel sx={{ display: isChecked ? 'visible' : 'none' }} control={<Checkbox onChange={(e) => onChecked(e)} />} label={textChecked} />
					<Button sx={{ display: isDynamicButton ? 'visible' : 'none' }} variant={colorDynamicButton} onClick={onDynamicButton}>
						{textDynamicButton}
					</Button>
					<Button sx={{ display: isCancel ? 'visible' : 'none' }} color='secondary' variant='contained' onClick={onCancel}>
						Back
					</Button>
					<Button sx={{ display: isCreate ? 'visible' : 'none' }} variant='kerry' onClick={onCreate}>
						Create
					</Button>
					<Button sx={{ display: isConfirm ? 'visible' : 'none' }} variant='kerry' onClick={onConfirm}>
						{textConfirm ?? 'Confirm'}
					</Button>
					<Button sx={{ display: isDownloadExcel ? 'visible' : 'none' }} variant='spreadsheet' onClick={onDownloadExcel}>
						{textDownloadExcel ?? 'Download Spreadsheet'}
					</Button>
					<Button sx={{ display: isDownloadPdf ? 'visible' : 'none' }} variant='pdf' onClick={onDownloadPdf}>
						{textDownloadPdf ?? 'Download PDF'}
					</Button>
					<Button sx={{ display: isPrint ? 'visible' : 'none' }} variant='pdf' onClick={onPrint}>
						Print
					</Button>
					<Button sx={{ display: isUpload ? 'visible' : 'none' }} variant='upload' onClick={toggleUploadDialog}>
						Upload
					</Button>
					<Button sx={{ display: isSynchronize ? 'visible' : 'none' }} variant='synch' onClick={onSynchronize}>
						Synchronize
					</Button>
					<Button sx={{ display: isCheck ? 'visible' : 'none' }} variant='check' onClick={onCheck}>
						Check
					</Button>
					<Button sx={{ display: isGenerate ? 'visible' : 'none' }} variant='generate' onClick={onGenerate}>
						Generate
					</Button>
				</div>
			</MUIToolbar>
			<UploadDialog
				name='file_upload'
				type={uploadType}
				isOpen={isOpen}
				toggle={toggleUploadDialog}
				onUpload={onUpload}
				onConfirm={onConfirm}
			/>
		</Paper>
	);
}

Toolbar.defaultProps = {
	isOpen				: false,
	isCancel			: false,
	onCancel			: () => { },
	isCreate			: false,
	onCreate			: () => { },
	isConfirm			: false,
	onConfirm			: () => { },
	isDownloadExcel		: false,
	onDownloadExcel		: () => { },
	isDownloadPdf		: false,
	onDownloadPdf		: () => { },
	isSynchronize		: false,
	onSynchronize		: () => { },
	uploadType			: '',
	isUpload			: false,
	onUpload			: () => { },
	isGenerate			: false,
	onGenerate			: () => { },
	isCheck				: false,
	onCheck				: () => { },
	onNavigate			: () => { },
	isPrint				: false,
	onPrint				: () => { },
	isDynamicButton 	: false,
	onDynamicButton 	: () => { },
	colorDynamicButton	: 'spreadsheet',
	textDynamicButton	: 'Download',
	textDownloadExcel	: 'Download Spreadsheet',
	onChecked			: () => { },
	textChecked			: 'Download DR 2',
	isChecked			: false,
}

export default Toolbar;